.background {
  display: block;
  width: 82vw;
  height: 100vh;
  background-size: cover !important;
  /* background: skyblue; */
  margin-left: 18%;
  transition: all 1s;
}

.img_plashka {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  opacity: 0;
}

.background > div > img {
  width: 100%;
  height: 100%;
}

.border {
  border: 2px solid white;
  width: 78vw;
  height: 96vh;
  margin: 0 auto;
  margin-top: 2vh;
  /* background: silver; */
  transition: all 1s;
}

.layout-white {
  height: inherit;
  width: inherit;
  background: white;
  opacity: 0;
  transition: all .6s;
  overflow-x: hidden;
  &_active {
    opacity: 1;
  }
}

.active {
  background: white;
}

@media (max-width: 1100px) {
  .background {
    width: 100vw;
    margin-left: 0;
  }
  .border {
    width: 96vw;
    transition: all .6s;
  }
}
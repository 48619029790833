body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: white;
  user-select: none;
}

.App {
  display: block;
  font-size: 16px;
  display: flex;
  h2 {
    font-family: "BookAntiquaRegula";
    text-transform: uppercase;
    color: black;
    font-size: 18px;
  }
}

* {
  margin: 0;
  padding: 0;
}

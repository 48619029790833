.photo_gallery {
  background: white;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

@media (max-width: 1100px) {
  .photo_gallery {
    padding-left: 260px;
    padding-right: 80px;
  }
}
.actions {
  width: 100%;
  height: 100%;
  background: #fff;
  font-family: 'BookAntiquaRegula';

  .header {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    font-family: 'BookAntiquaRegula';
    color: black;
    margin-bottom: 5%;
}
  .content-wrapper {
    padding-left: 10%;
    width: 80%;
    align-items: flex-start;
    .action {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      .action_text {
        padding-top: 30px;
        width: 60%;
      }
      .action_image {
      width: 300px;
      height: 200px;
      }
      &:last-child {
        .action_text {
          padding-top: 20px;
          width: 100%;
        }
      }
    }

  }
}

.contacts {
    width: 100%;
    height: 100%;
    background: #fff;
    font-family: 'BookAntiquaRegula';
}

.header {
    width: 100%;
    height: 35%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    font-family: 'BookAntiquaRegula';
}

.title {
    font-family: 'BookAntiquaRegula';
    font-weight: 700;
    font-size: 22px;
    position: relative;
}

.title::after {
    content: '';
    width: 2em;
    bottom: -5px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    position: absolute;
    height: 2px;
    background: black;
}

.bottom {
    width: 100%;
    height: 65%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.contact-info {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
}

.text {
    text-align: center;
    /* margin-top: 22px; */
}

.map-container {
    width: 50%;
    height: 100%;
}

.info-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 180px;
}

.map {
    margin-left: 20px;
    margin-top: 20px;
}

.soc-icons {
    width: 150px;
    height: 74px;
    /* margin-top: -287px; */
    display: flex;
    justify-content: space-between;
    .instagram {
      width: 100%;
      height: 50%;
      background: url('./img/instagram_logo.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
}

@media (max-width: 1100px){
    .title::after {
        left: 47.5%;
    }
}
.events {
    width: 100%;
    height: 100%;
    background: #fff;
    font-family: 'BookAntiquaRegula';
  .header {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: center;
    font-family: 'BookAntiquaRegula';
    color: black;
    margin-bottom: 5%;
}
  .content-wrapper {
    padding-left: 10%;
    width: 80%;
    align-items: flex-start;
    .text_title {
      margin-bottom: 15px;
      align-self: center;
    }
    .text_content {
      margin-bottom: 7px;
    }
  }
}


@media (max-width: 1100px) {

}
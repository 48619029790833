.about {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}

.info {
  background: white;
  width: 629px;
  height: 150px;
  margin-top: 45%;
  float: right;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 1.15em;
  font-family: "BookAntiquaRegula";
  margin-bottom: 40px;
}

.info p {
  padding: 20px 30px 20px 50px;
  background: white;
}

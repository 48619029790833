.foodMenu {
    height: 100%;
    width: 100%;
    background: white;
    font-family: 'BookAntiquaRegula';
}

.header_foodMenu {
    height: 20%;
    margin: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.toggler_li {
    font-weight: 600;
    font-size: 18px;
}

.toggler_li:after {
    position: relative;
    content: '';
    width: 100px;
    height: 5px;
    background: black;
}

.toggler {
    margin-top: 50px;
    list-style: none;
    display: flex;
    justify-content: space-between;
    width: 350px;
    color: #666666;
}

.active_toggle {
    color: black;
}

.toggler li {
    transition: all .6s;
    cursor: pointer;
}

.underline {
    width: 90px;
    height: 2px;
    background: black;
    transition: all .6s;
    margin-top: 5px;
    transition-timing-function: ease-in-out;
}

.content_menu {
    overflow-x: hidden;
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.subtitle {
    font-size: 20px;
}

.dish-name {
  display: flex;
  align-items: baseline;
  margin-bottom: 10px;
  border-bottom: 1px dotted;
  width: 400px;
  justify-content: space-between;
  align-items: flex-end;
}

.span-dish-name, .span-dish-price{
    position: relative;
    // margin-bottom: -20px;
    background: #fff;
}

.dots {
  flex: 1;
  overflow: hidden;
}

.content-wrapper {
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    height: 80%;
    color: #666666;
}

.dishes {
    margin-top: 15px;
}

.content-block {
    margin-top: 50px;
}

.content-block:first-child {
    margin-top: 10px;
    padding-top: 10px;
}

.content-block:last-child {
    padding-bottom: 30px;
}

.content_wine {
    height: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}
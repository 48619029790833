.menu {
    height: 100vh;
    width: 18%;
    min-width: 200px;
    position: fixed;
    text-decoration: none;
    color: #666666;
    font-family: 'BookAntiquaRegula';
    
    .li_active {
        &:after {
            position: absolute;
            content: '';
            margin-top: 23px;
            left: 0;
            transform: translateX(288%);
            width: 15%;
            height: 2px;
            background: black;
            animation: fadeIn .6s;
        }
    }
}
.burger {
    width: 75px;
    height: 75px;
    position: absolute;
    top: 30px;
    left: 50px;
    background: url('./img/menu.svg');
    background-size: cover;
}

.layout {
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, .7);
    animation: fadeIn .6s both;    
}

.main-menu {
    position: fixed;
    background: white;
    z-index: 2;
    width: 18%;
    height: 100vh;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

.hide_menu_active {
    animation: fadeIn .6s both;
    width: 250px;
}

.logo {
    width: 195px;
    height: 135px;
    background: url('./img/logo.png');
    background-size: cover;
    margin: 0 auto;
    margin-top: -117px;
}

.navigation {
    margin-top: -100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;    
    list-style: none;
    padding: 0;
}

.navigation li {
    text-decoration: none;    
    margin-top: 20px;
    width: 100%;
}

a {
    text-decoration: none;
    color: #666666;    
}

